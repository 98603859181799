import { AssetActionDialog } from '@/asset/components/action-dialog';
import { AssetActionTimeline } from '@/asset/components/action-timeline';
import { AssetInventoryDateControl } from '@/asset/components/asset-inventory-date-control';
import { useAsset } from '@/asset/hooks/use-asset';
import { useDisclosure } from '@/hooks/use-disclosure';
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/assets/$assetKey/')({
  component: AssetIndexRoute,
});

function AssetIndexRoute() {
  const { assetKey } = Route.useParams();
  const { asset } = useAsset(assetKey);

  const actionDialog = useDisclosure();

  return (
    <Paper variant="outlined">
      <AssetActionDialog
        isOpen={actionDialog.isOpen}
        assetId={assetKey}
        onClose={actionDialog.close}
      />
      <Stack
        useFlexGap
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        paddingRight={2}
      >
        <Typography variant="subtitle2" margin={2}>
          Basic info
        </Typography>
      </Stack>
      <Divider />
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <Stack spacing={2} padding={2}>
          <Typography variant="subtitle2">Details</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">Inventory date</Typography>
            <AssetInventoryDateControl asset={asset} />
          </Stack>
        </Stack>
        <Stack padding={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2">Activity</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={actionDialog.open}
            >
              Action
            </Button>
          </Stack>
          <AssetActionTimeline asset={asset} />
        </Stack>
      </Box>
    </Paper>
  );
}
