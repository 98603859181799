import { z } from 'zod';

const timelineDensitySchema = z.enum(['day', 'week', 'month']);

export type TimelineDensity = z.infer<typeof timelineDensitySchema>;

export const TIMELINE_DENSITY_LABELS: Record<TimelineDensity, string> = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
};

export const TIMELINE_DENSITY_OPTIONS = timelineDensitySchema.options;

/**
 * Checks if a value is a valid timeline density value.
 *
 * @param value - The value to check.
 */
export function isTimelineDensity(value: unknown): value is TimelineDensity {
  const result = timelineDensitySchema.safeParse(value);

  return result.success;
}
